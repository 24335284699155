<template>
  <div class="merchants-list">
    <page-content>
      <search-panel
        show-btn
        flex
        @getList="getList"
      >
        <div>
          <p>ID</p>
          <el-input
            v-model="id"
            clearable
            placeholder="请输入ID"
            size="mini"
          />
        </div>
        <div>
          <p>商家名称</p>
          <el-input
            v-model="merchantsName"
            clearable
            placeholder="请输入商家名称"
            size="mini"
          />
        </div>
        <div>
          <p>所属区域</p>
          <el-cascader
            v-model="address"
            :options="addressOptions"
            size="mini"
            placeholder="请选择地址"
            @change="addressChange"
          ></el-cascader>
        </div>
        <div>
          <p>手机号</p>
          <el-input
            v-model="mobile"
            clearable
            placeholder="请输入手机号"
            size="mini"
          />
        </div>
        <div>
          <p>入驻开始日期</p>
          <el-date-picker
            v-model="begainValue"
            placeholder="选择开始日期"
            clearable
            size="mini"
            @change="changesTime"
          />
        </div>
        <div>
          <p>入驻结束日期</p>
          <el-date-picker
            v-model="endValue"
            placeholder="选择结束日期"
            clearable
            size="mini"
            @change="changeeTime"
          />
        </div>
      </search-panel>
    </page-content>
    <page-content>
      <div style="padding: 20px 0;">
        <el-button
          slot="button-right"
          type="primary"
          size="mini"
          @click="operation('add')"
        >
          导 出
        </el-button>
      </div>
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
        element-loading-text="拼命加载中"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="multipleSelectShow"
          type="selection"
          width="55"
        >
        </el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          width="90"
        />
        <el-table-column
          prop="name"
          label="商家名称"
          width="120"
        />
        <el-table-column
          prop="address"
          label="所属区域"
          width="120"
        />
        <el-table-column
          prop="privilion"
          label="所属展馆"
          width="120"
        />
        <el-table-column
          prop="is_supply"
          label="商家商品类型"
        />
        <el-table-column
          prop="merchants_level"
          label="商家层级"
          width="120"
        />
        <el-table-column
          prop="cate"
          label="关联后台类目"
          width="120"
        />
        <el-table-column
          prop="ctime"
          label="入驻时间"
          width="120"
        />
        <el-table-column
          prop="count"
          label="在售商品数量"
          width="120"
        />
        <el-table-column
          prop="sale"
          label="销售额（元）"
          width="120"
        />
        <el-table-column
          prop="percent"
          label="商家超时发货率"
          width="120"
        />
        <el-table-column
          prop="return_percent"
          label="商家投诉率"
          width="120"
        />
        <el-table-column
          prop="operator"
          label="运营负责人"
          width="120"
        />
        <el-table-column
          prop="note"
          label="备注记录"
          width="120"
        />
        <el-table-column
          prop="status"
          label="状态"
          width="120"
        />
        <el-table-column
          prop="status"
          label="操作"
          width="350"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                type="success"
                size="mini"
                @click="operation(scope,2)"
              >
                查看
              </el-button>
              <el-button
                type="warning"
                size="mini"
                @click="operation(scope,3)"
              >
                编辑
              </el-button>
              <el-button
                type="primary"
                size="mini"
                @click="operation(scope,4)"
              >
                审核
              </el-button>
              <el-button
                type="danger"
                size="mini"
                @click="operation(scope,0)"
              >
                禁用
              </el-button>
              <el-button
                type="success"
                size="mini"
                @click="operation(scope,1)"
              >
                启用
              </el-button>
              <el-dropdown @command="commandHandle">
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="store">
                    查看店铺
                  </el-dropdown-item>
                  <el-dropdown-item command="note">
                    备注
                  </el-dropdown-item>
                  <el-dropdown-item command="strategy">
                    运营策略
                  </el-dropdown-item>
                  <el-dropdown-item command="reward">
                    商家证书/奖励
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination-panel
        :page="pagination"
        :load="loading"
        show-select
        @getList="getList"
      />
    </page-content>
    <!-- 备注填写弹窗 -->
    <el-dialog
      title="添加备注"
      :visible.sync="noteDialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item
          label="配合度"
          prop="type"
        >
          <el-select
            v-model="ruleForm.type"
            placeholder="请选择配合度"
          >
            <el-option
              label="积极"
              value="0"
            ></el-option>
            <el-option
              label="一般"
              value="1"
            ></el-option>
            <el-option
              label="不积极"
              value="2"
            ></el-option>
            <el-option
              label="消极"
              value="3"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="备注内容"
          prop="desc"
        >
          <el-input
            v-model="ruleForm.note"
            type="textarea"
          ></el-input>
        </el-form-item>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="dialogVisible = false"
          >确 定</el-button>
        </span>
      </el-form>
    </el-dialog>
    <el-dialog
      title="备注记录"
      :visible.sync="noteHistoryDialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-table
        :data="noteTableData"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          label="备注人"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="备注时间"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="note"
          label="备注内容"
        >
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { confirm } from '@/assets/js/confirmUrl'
export default {
  name: 'MerchantsList',
  data() {
    return {
      id: '', // id
      name: '',
      storeName: '', // 店铺名称
      user_mobile: '',
      order_sn: '',
      mobile: '',
      tableData: [],
      legal_person: '',
      loading: false,
      begainValue: '',
      endValue: '',
      sTime: '',
      eTime: '',
      bankNumber: '', // 账号
      roleName: '', // 角色名称
      roleNameArr: [
        {
          label: '全部',
          value: -1
        },
        {
          label: '启用',
          value: 0
        },
        {
          label: '禁用',
          value: 1
        }
      ], // 角色状态
      multipleSelection: [], // 多选
      multipleSelectShow: false, // 多选控制器
      serviceStatus: '', // 是否是服务商
      serviceStatusArr: [
        {
          label: '全部',
          value: -1
        },
        {
          label: '是',
          value: 0
        },
        {
          label: '否',
          value: 1
        }
      ],
      // 账号状态
      accountStatus: '',
      accountStatusArr: [
        {
          label: '全部',
          value: -1
        },
        {
          label: '启用',
          value: 0
        },
        {
          label: '禁用',
          value: 1
        }
      ],
      address: '', // 区域
      addressOptions: [], // 区域选项
      pavilion: '', // 展馆
      pavilionOptions: [], // 展馆选项
      merchantsStatus: '', // 商家状态
      merchantsStatusOptions: [
        {
          label: '全部',
          value: -1
        },
        {
          label: '待审核',
          value: 0
        },
        {
          label: '审核未通过',
          value: 1
        },
        {
          label: '启用',
          value: 2
        },
        {
          label: '禁用',
          value: 3
        }
      ], // 商家状态选项
      merchantsName: '', // 商家名称
      merchantsLevel: '', // 商家层级选项
      merchantsLevelArr: [], // 商家层级
      operator: '', // 运营
      noteDialogVisible: false, // 备注弹窗
      noteHistoryDialogVisible: true, // 备注历史
      ruleForm: {
        type: '0',
        note: ''
      },
      rules: {},
      ruleFormDetail: {

      },
      noteTableData: [], // 备注内容
      pagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: '10'
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      this.$axios({
        method: 'get',
        url: this.$api.enter_nombo,
        params: {
          page: this.pagination.page,
          page_count: this.pagination.page_count,
          user_mobile: this.user_mobile,
          mobile: this.mobile,
          inviteStatus: this.inviteStatus
        }
      }).then((res) => {
        if (res.error === 0) {
          this.tableData = res.data.data
          this.pagination = res.data.pagination
        } else {
          this.$message(r.error_msg)
        }
        this.loading = false
      })
    },
    toDelete(item) {
      this.$confirm('确定删除此角色吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$api.delete_nombo, {
          apply_id: item.id
        }).then(res => {
          if (res.error === 0) {
            this.$message.success('删除成功')
            this.getList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // operation(type){
    //   if(type === 'add'){
    //     this.$router.push('/user/account/detail?type=add')
    //   }else if(type === 'multipleDel'){
    //     this.multipleSelectShow = true
    //   }
    // },
    // 跳转店铺列表
    toStoreList() {
      this.$router.push('/user/store/list')
    },
    // 状态改变
    changeState(item) {
      this.orderStatus = item.name
      this.getListChange()
    },
    changesTime() {
      if (this.begainValue) {
        this.sTime = Date.parse(this.begainValue) / 1000
      } else {
        this.sTime = ''
      }
    },
    changeeTime() {
      if (this.endValue) {
        this.eTime = Date.parse(this.endValue) / 1000
      } else {
        this.eTime = ''
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      console.log('多选', this.multipleSelection)
    },
    // 提交删除
    multipleDelSubmit() {
      this.toDelete()
    },
    // 禁用和启用
    operation(scope, type) {
      if (type === 0 || type === 1) {
        this.$confirm('确定修改此商家状态吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.post(this.$api.delete_nombo, {
            apply_id: scope.row.id
          }).then(res => {
            if (res.error === 0) {
              this.$message.success('操作成功')
              this.getList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
      } else if (type === 2 || type === 3) {
        this.$router.push({ path: '/user/merchants/detail', query: { id: scope.row.id } })
      } else if (type === 4) {
        this.$router.push({ path: '/user/merchants/audit', query: { id: scope.row.id } })
      }
    },
    // 地址选择
    addressChange() {

    },
    pavilionChange() {

    },
    // 下拉菜单事件
    commandHandle(type) {
      console.log(88, type)
      if (type === 'strategy') {
        this.editStrategy()
      } else if (type === 'reward') {
        this.editReward()
      } else if (type === 'note') {
        this.noteDialogVisible = true
      }
    },
    // 填写备注
    editStrategy() {
      console.log(23333)
      this.$prompt('请输入运营策略', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        this.$message({
          type: 'success',
          message: '操作成功'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    },
    // 填写备注
    editReward() {
      console.log(23333)
      this.$prompt('请输入商家证书或奖励名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        this.$message({
          type: 'success',
          message: '操作成功'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    },
    // 批量关联
    setOperator() {
      this.$prompt('请输入负责人名称', '填写运营负责人', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        this.$message({
          type: 'success',
          message: '操作成功'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    },
    // 关闭备注
    handleClose() {
      this.noteDialogVisible = false
    },
    setSelect() {

    },
    handleClose() {
      this.noteHistoryDialogVisible = false
    },
    outList() {
      let message = '是否确认导出数据'
      let url = this.$api.storeUser.getlist + '?download=1&contact=' + this.name + '&contact_number=' + this.mobile + '&city_name=' + this.city
      confirm(message, url)
    }
  }
}
</script>

<style scoped>
.el-dropdown-link{
  margin-left: 10px;
  cursor: pointer;

}
</style>
