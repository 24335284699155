var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "merchants-list" },
    [
      _c(
        "page-content",
        [
          _c(
            "search-panel",
            {
              attrs: { "show-btn": "", flex: "" },
              on: { getList: _vm.getList },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v("ID")]),
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入ID",
                      size: "mini",
                    },
                    model: {
                      value: _vm.id,
                      callback: function ($$v) {
                        _vm.id = $$v
                      },
                      expression: "id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("商家名称")]),
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入商家名称",
                      size: "mini",
                    },
                    model: {
                      value: _vm.merchantsName,
                      callback: function ($$v) {
                        _vm.merchantsName = $$v
                      },
                      expression: "merchantsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("所属区域")]),
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.addressOptions,
                      size: "mini",
                      placeholder: "请选择地址",
                    },
                    on: { change: _vm.addressChange },
                    model: {
                      value: _vm.address,
                      callback: function ($$v) {
                        _vm.address = $$v
                      },
                      expression: "address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("手机号")]),
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入手机号",
                      size: "mini",
                    },
                    model: {
                      value: _vm.mobile,
                      callback: function ($$v) {
                        _vm.mobile = $$v
                      },
                      expression: "mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("入驻开始日期")]),
                  _c("el-date-picker", {
                    attrs: {
                      placeholder: "选择开始日期",
                      clearable: "",
                      size: "mini",
                    },
                    on: { change: _vm.changesTime },
                    model: {
                      value: _vm.begainValue,
                      callback: function ($$v) {
                        _vm.begainValue = $$v
                      },
                      expression: "begainValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("入驻结束日期")]),
                  _c("el-date-picker", {
                    attrs: {
                      placeholder: "选择结束日期",
                      clearable: "",
                      size: "mini",
                    },
                    on: { change: _vm.changeeTime },
                    model: {
                      value: _vm.endValue,
                      callback: function ($$v) {
                        _vm.endValue = $$v
                      },
                      expression: "endValue",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "page-content",
        [
          _c(
            "div",
            { staticStyle: { padding: "20px 0" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    slot: "button-right",
                    type: "primary",
                    size: "mini",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.operation("add")
                    },
                  },
                  slot: "button-right",
                },
                [_vm._v(" 导 出 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-style": {
                  background: "#D9DDE1",
                  color: "#0D0202",
                  "font-weight": 400,
                },
                "element-loading-text": "拼命加载中",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _vm.multipleSelectShow
                ? _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "90" },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "商家名称", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "所属区域", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "privilion", label: "所属展馆", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "is_supply", label: "商家商品类型" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "merchants_level",
                  label: "商家层级",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "cate", label: "关联后台类目", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "ctime", label: "入驻时间", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "count", label: "在售商品数量", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "sale", label: "销售额（元）", width: "120" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "percent",
                  label: "商家超时发货率",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "return_percent",
                  label: "商家投诉率",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "operator", label: "运营负责人", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "note", label: "备注记录", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "操作", width: "350" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "success", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.operation(scope, 2)
                                  },
                                },
                              },
                              [_vm._v(" 查看 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "warning", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.operation(scope, 3)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.operation(scope, 4)
                                  },
                                },
                              },
                              [_vm._v(" 审核 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.operation(scope, 0)
                                  },
                                },
                              },
                              [_vm._v(" 禁用 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "success", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.operation(scope, 1)
                                  },
                                },
                              },
                              [_vm._v(" 启用 ")]
                            ),
                            _c(
                              "el-dropdown",
                              { on: { command: _vm.commandHandle } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "el-dropdown-link" },
                                  [
                                    _vm._v(" 更多"),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right",
                                    }),
                                  ]
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "store" } },
                                      [_vm._v(" 查看店铺 ")]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "note" } },
                                      [_vm._v(" 备注 ")]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "strategy" } },
                                      [_vm._v(" 运营策略 ")]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "reward" } },
                                      [_vm._v(" 商家证书/奖励 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination-panel", {
            attrs: {
              page: _vm.pagination,
              load: _vm.loading,
              "show-select": "",
            },
            on: { getList: _vm.getList },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加备注",
            visible: _vm.noteDialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.noteDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "配合度", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择配合度" },
                      model: {
                        value: _vm.ruleForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "type", $$v)
                        },
                        expression: "ruleForm.type",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "积极", value: "0" } }),
                      _c("el-option", { attrs: { label: "一般", value: "1" } }),
                      _c("el-option", {
                        attrs: { label: "不积极", value: "2" },
                      }),
                      _c("el-option", { attrs: { label: "消极", value: "3" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注内容", prop: "desc" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.ruleForm.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "note", $$v)
                      },
                      expression: "ruleForm.note",
                    },
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "备注记录",
            visible: _vm.noteHistoryDialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.noteHistoryDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.noteTableData },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "备注人", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "备注时间", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "note", label: "备注内容" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }